import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ManualEntry.css';

const ManualEntry = () => {
  const [zones, setZones] = useState({
    zone1Min: '',
    zone1Max: '',
    zone2Min: '',
    zone2Max: '',
    zone3Min: '',
    zone3Max: '',
    zone4Min: '',
    zone4Max: '',
    zone5Min: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setZones((prevZones) => ({
      ...prevZones,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    /*
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const selectedGenres = JSON.parse(localStorage.getItem('selectedGenres'));

    const userDocument = {
      Username: username,
      Password: password,
      HeartRateZones: {
        zone_1_min: zones.zone1Min,
        zone_1_max: zones.zone1Max,
        zone_2_min: zones.zone2Min,
        zone_2_max: zones.zone2Max,
        zone_3_min: zones.zone3Min,
        zone_3_max: zones.zone3Max,
        zone_4_min: zones.zone4Min,
        zone_4_max: zones.zone4Max,
        zone_5_min: zones.zone5Min,
      },
      TrainingPlan: {},
      SavedPlaylists: {},
      MusicPreferences: {
        music_pref: selectedGenres,
        music_exclusions: {
          artist_excl: [],
          song_excl: [],
          explicit_excl: "No"
        }
      }    
    };

    try {
      await axios.post('/create-user', userDocument);
      navigate('/login');
    } catch (error) {
      console.error('Error creating user', error);
    }
    */
    alert('Create account unavailable: testing has now finished');
  };

  return (
    <div className="manual-entry-container">
      <h2>Enter your heart rate zones</h2>
      <p>You can find your heart rate zones in most wearable devices.</p>
      <form className="zones-form">
        <div className="left-column">
          <label>Zone 5 min:</label>
          <input type="number" name="zone5Min" value={zones.zone5Min} onChange={handleChange} />

          <label>Zone 4 min:</label>
          <input type="number" name="zone4Min" value={zones.zone4Min} onChange={handleChange} />

          <label>Zone 3 min:</label>
          <input type="number" name="zone3Min" value={zones.zone3Min} onChange={handleChange} />

          <label>Zone 2 min:</label>
          <input type="number" name="zone2Min" value={zones.zone2Min} onChange={handleChange} />

          <label>Zone 1 min:</label>
          <input type="number" name="zone1Min" value={zones.zone1Min} onChange={handleChange} />
        </div>

        <div className="right-column">
          <label className="spacer"></label>

          <label>Zone 4 max:</label>
          <input type="number" name="zone4Max" value={zones.zone4Max} onChange={handleChange} />

          <label>Zone 3 max:</label>
          <input type="number" name="zone3Max" value={zones.zone3Max} onChange={handleChange} />

          <label>Zone 2 max:</label>
          <input type="number" name="zone2Max" value={zones.zone2Max} onChange={handleChange} />

          <label>Zone 1 max:</label>
          <input type="number" name="zone1Max" value={zones.zone1Max} onChange={handleChange} />
        </div>
      </form>
      <div className="button-container">
        <button onClick={handleSubmit}>Create Account</button>
      </div>
    </div>
  );
};

export default ManualEntry;
