import React from 'react';
import './LoggedOutHomepage.css';

import Logo from '../../../components/Logo/Logo';
import Buttons from '../../../components/Buttons/Buttons';

function LoggedOutHomepage() {
  return (
    <div className="LoggedOutHomepage">
      <Logo className="App-logo" />
      <main className="App-main">
        <div className="welcome-text">
          <h1>Welcome to Running Mate</h1>
        </div>
        <div className="cta-buttons">
          <Buttons to="/login" label="Log in" />
          <Buttons to="/signup" label="Sign up" />
        </div>
      </main>
    </div>
  );
}

export default LoggedOutHomepage;

