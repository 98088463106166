// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-page-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #333;
    color: #fff;
    text-align: left;
}

.loading-page-container h2 {
    color: #fff;
    margin-bottom: 20px;
}

.loading-page-container p {
    margin-bottom: 20px;
}

.loading-page-container ul {
    list-style-type: disc; 
    margin: 0;
    padding-left: 20px; 
}

.loading-page-container ul li {
    margin-bottom: 10px; 
}

.loading-page-container .note {
    margin-top: 20px;
    font-style: italic;
}

.loading-indicator {
    width: 40px;
    height: 40px;
    border: 5px solid #ccc;
    border-top: 5px solid #fff;
    border-radius: 50%;
    margin: 20px auto;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreatePlaylist/LoadingPage/LoadingPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;IAClB,iBAAiB;IACjB,kCAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".loading-page-container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    background-color: #333;\n    color: #fff;\n    text-align: left;\n}\n\n.loading-page-container h2 {\n    color: #fff;\n    margin-bottom: 20px;\n}\n\n.loading-page-container p {\n    margin-bottom: 20px;\n}\n\n.loading-page-container ul {\n    list-style-type: disc; \n    margin: 0;\n    padding-left: 20px; \n}\n\n.loading-page-container ul li {\n    margin-bottom: 10px; \n}\n\n.loading-page-container .note {\n    margin-top: 20px;\n    font-style: italic;\n}\n\n.loading-indicator {\n    width: 40px;\n    height: 40px;\n    border: 5px solid #ccc;\n    border-top: 5px solid #fff;\n    border-radius: 50%;\n    margin: 20px auto;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
