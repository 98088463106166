// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoggedInHomepage {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #333;
}

.LoggedInHomepage h1 {
  color: white;
}

.LoggedInHomepage p {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
}

.button-container button {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoggedInHomepage/LoggedInHomepage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".LoggedInHomepage {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  background-color: #333;\n}\n\n.LoggedInHomepage h1 {\n  color: white;\n}\n\n.LoggedInHomepage p {\n  text-align: center;\n  margin-bottom: 20px;\n  color: white;\n}\n\n.button-container {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  margin-top: 20px;\n}\n\n.button-container button {\n  padding: 10px;\n  margin-bottom: 10px;\n  width: 100%;\n  max-width: 100%;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
