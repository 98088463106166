// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #333;
}

.signup-container h2 {
  color: white;
}

.form-group {
  margin-bottom: 20px; 
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.form-group label {
  color: white;
  flex: 1 1; 
  text-align: left; 
  margin-right: 10px; 
}

.form-group input {
  flex: 2 1; 
  width: calc(100% - 10px);
  padding: 8px;
  box-sizing: border-box;
  text-align: right;
}

.error {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Account/Signup/Signup.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,SAAO;EACP,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,wBAAwB;EACxB,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".signup-container {\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  background-color: #333;\n}\n\n.signup-container h2 {\n  color: white;\n}\n\n.form-group {\n  margin-bottom: 20px; \n  display: flex;\n  justify-content: space-between; \n  align-items: center;\n}\n\n.form-group label {\n  color: white;\n  flex: 1; \n  text-align: left; \n  margin-right: 10px; \n}\n\n.form-group input {\n  flex: 2; \n  width: calc(100% - 10px);\n  padding: 8px;\n  box-sizing: border-box;\n  text-align: right;\n}\n\n.error {\n  color: red;\n  margin-bottom: 15px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
