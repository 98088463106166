import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './IntervalsSetup.css';

const IntervalsSetup = () => {
  const [template, setTemplate] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { runType } = location.state || { runType: 'interval_training' };

  axios.defaults.baseURL = 'https://www.therunningmate.co.uk';  
  // axios.defaults.baseURL = 'http://localhost:5000';

  const runTypeTitles = {
    interval_training: 'Intervals Setup',
    base_run: 'Base Run Setup',
    long_run: 'Long Run Setup',
    progression_run: 'Progression Run Setup'
  };

  const fetchTemplate = useCallback(async () => {
    try {
      const response = await axios.get('/api/get_run_template', {
        params: { template_name: runType }
      });
      const activity = response.data;
      console.log('Fetched template (activity):', activity);  // Log the fetched template
      setTemplate(activity);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setError('Failed to fetch data');
      setLoading(false);
    }
  }, [runType]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const handleChange = (index, field, value) => {
    const updatedData = [...template.data];
    updatedData[index][field] = value;
    setTemplate({ ...template, data: updatedData });
  };

  const handleAddStage = () => {
    const newStage = {
      HRZ: 0,
      Order: template.data.length,
      Stage: 'New Stage',
      'Time (hours)': 0,
      'Time (minutes)': 0,
      'Time (seconds)': 0,
    };
    setTemplate({ ...template, data: [...template.data, newStage] });
  };

  const handleDeleteStage = (index) => {
    const updatedData = template.data.filter((_, i) => i !== index)
                                      .map((stage, i) => ({ ...stage, Order: i }));
    setTemplate({ ...template, data: updatedData });
  };

  const handleMoveStage = (index, direction) => {
    if ((index === 0 && direction === -1) || (index === template.data.length - 1 && direction === 1)) return;

    const updatedData = [...template.data];
    const [movedStage] = updatedData.splice(index, 1);
    updatedData.splice(index + direction, 0, movedStage);

    const reorderedData = updatedData.map((stage, i) => ({ ...stage, Order: i }));
    setTemplate({ ...template, data: reorderedData });
  };

  const handleNext = () => {
    if (!template) {
      console.error('Template data is missing, cannot proceed.');
      return;
    }
    console.log('Next button clicked with activity (template):', template);  
    navigate('/ChooseMusicSource', { state: { activity: template } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="IntervalsSetup">
      <h1>{runTypeTitles[runType]}</h1>
      <table>
        <thead>
          <tr>
            <th>Stage</th>
            <th>Time (hours)</th>
            <th>Time (minutes)</th>
            <th>Time (seconds)</th>
            <th>HRZ</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {template.data
            .sort((a, b) => a.Order - b.Order)
            .map((stage, index) => (
              <tr key={index}>
                <td data-label="Stage">
                  <input
                    type="text"
                    value={stage.Stage}
                    onChange={(e) => handleChange(index, 'Stage', e.target.value)}
                  />
                </td>
                <td data-label="Time (hours)">
                  <input
                    type="number"
                    value={stage['Time (hours)']}
                    onChange={(e) => handleChange(index, 'Time (hours)', e.target.value)}
                  />
                </td>
                <td data-label="Time (minutes)">
                  <input
                    type="number"
                    value={stage['Time (minutes)']}
                    onChange={(e) => handleChange(index, 'Time (minutes)', e.target.value)}
                  />
                </td>
                <td data-label="Time (seconds)">
                  <input
                    type="number"
                    value={stage['Time (seconds)']}
                    onChange={(e) => handleChange(index, 'Time (seconds)', e.target.value)}
                  />
                </td>
                <td data-label="HRZ">
                  <input
                    type="number"
                    value={stage.HRZ}
                    onChange={(e) => handleChange(index, 'HRZ', e.target.value)}
                  />
                </td>
                <td data-label="Actions" className="actions-cell">
                  <select onChange={(e) => {
                    const action = e.target.value;
                    if (action === 'move-up') handleMoveStage(index, -1);
                    else if (action === 'move-down') handleMoveStage(index, 1);
                    else if (action === 'delete') handleDeleteStage(index);
                    e.target.value = '';
                  }}>
                    <option value="">Select Action</option>
                    <option value="move-up" disabled={index === 0}>Move Up</option>
                    <option value="move-down" disabled={index === template.data.length - 1}>Move Down</option>
                    <option value="delete">Delete</option>
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <button onClick={handleAddStage}>Add Stage</button>
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default IntervalsSetup;
