import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CalculateHR.css';

const CalculateHR = () => {
  const [age, setAge] = useState('');
  // const [isFemale, setIsFemale] = useState('');
  const [zones, setZones] = useState(null);
  const navigate = useNavigate();

  // Modified getEstMaxHr to only use the general formula
  const getEstMaxHr = (age) => {
    return 220 - age;  // Fox formula
    // if (sex === 'yes') {
    //   return 206 - (0.88 * age);  // Gulati formula
    // } else {
    //   return 220 - age;  // Fox formula
    // }
  };

  const hrzCalculations = (estMaxHr) => {
    return {
      zone1Min: Math.round(estMaxHr * 0.5),
      zone1Max: Math.round(estMaxHr * 0.6),
      zone2Min: Math.round(estMaxHr * 0.6),
      zone2Max: Math.round(estMaxHr * 0.7),
      zone3Min: Math.round(estMaxHr * 0.7),
      zone3Max: Math.round(estMaxHr * 0.8),
      zone4Min: Math.round(estMaxHr * 0.8),
      zone4Max: Math.round(estMaxHr * 0.9),
      zone5Min: Math.round(estMaxHr * 0.9),
    };
  };

  const handleCalculate = () => {
    const estMaxHr = getEstMaxHr(age /*, isFemale*/);
    const calculatedZones = hrzCalculations(estMaxHr);
    setZones(calculatedZones);
  };

  const handleSubmit = async () => {
    /*
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const userDocument = {
      Username: username,
      Password: password,
      HeartRateZones: {
        zone_1_min: zones.zone1Min,
        zone_1_max: zones.zone1Max,
        zone_2_min: zones.zone2Min,
        zone_2_max: zones.zone2Max,
        zone_3_min: zones.zone3Min,
        zone_3_max: zones.zone3Max,
        zone_4_min: zones.zone4Min,
        zone_4_max: zones.zone4Max,
        zone_5_min: zones.zone5Min,
      },
      TrainingPlan: {},
      SavedPlaylists: {},
      MusicPreferences: {
        music_pref: JSON.parse(localStorage.getItem('selectedGenres')) || [],
        music_exclusions: {
          artist_excl: [],
          song_excl: [],
          explicit_excl: "No"
        }
      }    
    };

    try {
      await axios.post('/create-user', userDocument);
      navigate('/login');
    } catch (error) {
      console.error('Error creating user', error);
    }
    */
    alert('Create account unavailable: testing has now finished');
  };

  return (
    <div className="manual-entry-container">
      <h2>Calculate Heart Rate Zones</h2>
      <p>
        We can calculate your Heart Rate Zones using your age:
        please enter it below. 
        {/* We ask the optional question of 'Are you female?' 
        as we will use a different algorithm to calculate your Heart Rate Zones if you answer 'yes'. 
        If left blank, a general algorithm will be used. */}
      </p>
      <div className="zones-form">
        <div className="input-group">
          <label htmlFor="age">Age:</label>
          <input 
            type="number" 
            id="age" 
            value={age} 
            onChange={(e) => setAge(e.target.value)} 
          />
        </div>
        {/* <div className="input-group">
          <label htmlFor="isFemale">Are you female?</label>
          <select 
            id="isFemale" 
            value={isFemale} 
            onChange={(e) => setIsFemale(e.target.value)}
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
          </select>
        </div> */}
      </div>
      <button onClick={handleCalculate}>Calculate my heart rate zones</button>
      {zones && (
        <div className="zones-display">
          <h2>Your Heart Rate Zones</h2>
          <table className="zones-table">
            <thead>
              <tr>
                <th>Zone</th>
                <th>Min HR</th>
                <th>Max HR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zone 1</td>
                <td>{zones.zone1Min}</td>
                <td>{zones.zone1Max}</td>
              </tr>
              <tr>
                <td>Zone 2</td>
                <td>{zones.zone2Min}</td>
                <td>{zones.zone2Max}</td>
              </tr>
              <tr>
                <td>Zone 3</td>
                <td>{zones.zone3Min}</td>
                <td>{zones.zone3Max}</td>
              </tr>
              <tr>
                <td>Zone 4</td>
                <td>{zones.zone4Min}</td>
                <td>{zones.zone4Max}</td>
              </tr>
              <tr>
                <td>Zone 5</td>
                <td>{zones.zone5Min}</td>
                <td>-</td> {/* No Max HR for Zone 5 */}
              </tr>
            </tbody>
          </table>
          <button onClick={handleSubmit}>Create Account</button>
        </div>
      )}
    </div>
  );
};

export default CalculateHR;
