import React from 'react';
import logo from '../../assets/logo192.png'; 

function Logo() {
  return (
    <div className="App-logo">
      <img src={logo} alt="App Logo" />
    </div>
  );
}

export default Logo;
