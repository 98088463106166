import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import axios from 'axios';

import Login from './pages/Account/Login/Login';
import Signup from './pages/Account/Signup/Signup';
import LoggedInHomepage from './components/LoggedInHomepage/LoggedInHomepage';
import LoggedOutHomepage from './pages/Account/LoggedOutHomepage/LoggedOutHomepage';
import Selectmusic from './pages/Account/Selectmusic/Selectmusic';
import SetHRZ from './pages/Account/SetHRZ/SetHRZ';
import ManualEntry from './pages/Account/ManualEntry/ManualEntry';
import CalculateHR from './pages/Account/CalculateHR/CalculateHR';
import ChooseRunType from './pages/CreatePlaylist/ChooseRunType/ChooseRunType';
import IntervalsSetup from './pages/CreatePlaylist/IntervalsSetup/IntervalsSetup';
import ChooseMusicSource from './pages/CreatePlaylist/ChooseMusicSource/ChooseMusicSource';
import CreatePlaylistAction from './pages/CreatePlaylist/CreatePlaylistAction/CreatePlaylistAction';
import DisplayPlaylist from './pages/Playlist/DisplayPlaylist/DisplayPlaylist';
import LoadingPage from './pages/CreatePlaylist/LoadingPage/LoadingPage';
import ChooseSpecificMusic from './pages/CreatePlaylist/ChooseSpecificMusic/ChooseSpecificMusic';
import SavedPlaylists from './pages/Playlist/SavedPlaylists/SavedPlaylists';
import StoreToken from './components/StoreToken/StoreToken';

axios.defaults.baseURL = 'https://www.therunningmate.co.uk/api';
// axios.defaults.baseURL = 'http://localhost:5000'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Redirect to LoggedInHomepage if logged in, otherwise show LoggedOutHomepage */}
          <Route
            path="/"
            element={isLoggedIn ? <Navigate to="/LoggedInHomepage" /> : <LoggedOutHomepage />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/selectmusic" element={<Selectmusic />} />
          <Route path="/LoggedInHomepage" element={<LoggedInHomepage />} />
          <Route path="/setHRZ" element={<SetHRZ />} />
          <Route path="/manualEntry" element={<ManualEntry />} />
          <Route path="/calculateHR" element={<CalculateHR />} />
          <Route path="/ChooseRunType" element={<ChooseRunType />} /> 
          <Route path="/intervals-setup" element={<IntervalsSetup />} />
          <Route path="/ChooseMusicSource" element={<ChooseMusicSource />} />
          <Route path="/CreatePlaylistAction" element={<CreatePlaylistAction />} />
          <Route path="/display-playlist" element={<DisplayPlaylist />} />
          <Route path="/loading-page" element={<LoadingPage />} />
          <Route path="/specific-music" element={<ChooseSpecificMusic />} />
          <Route path="/saved-playlists" element={<SavedPlaylists />} />
          <Route path="/store-token" element={<StoreToken />} />               
        </Routes>
      </div>
    </Router>
  );
}

export default App;
