import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CreatePlaylistAction.css';
import LoadingPage from '../LoadingPage/LoadingPage';

const CreatePlaylistAction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const activity = location.state?.activity;
    const selectedGenres = useMemo(() => location.state?.selectedGenres || [], [location.state?.selectedGenres]);
    const username = localStorage.getItem('username');
    const accessToken = localStorage.getItem('spotify_access_token'); 
    const runningMateToken = localStorage.getItem('token'); 

    useEffect(() => {
        console.log('Activity:', activity);
        console.log('Selected Genres:', selectedGenres);
    }, [activity, selectedGenres]);

    const handleCreatePlaylist = async () => {
        setIsButtonDisabled(true); // Disable the button
        setLoading(true); // Start loading

        if (!runningMateToken) {
            console.error('User is not logged in to Running Mate.');
            navigate('/login');
            return;
        }

        if (!accessToken) {
            console.error('Spotify access token is missing. This should have been handled earlier.');
            setIsButtonDisabled(false);
            setLoading(false);
            return;
        }

        const requestBody = {
            username: username,
            activity: activity,
            MusicPreferences: selectedGenres,
        };

        try {
            const response = await fetch('https://www.therunningmate.co.uk/api/create_playlist', { 
            // const response = await fetch('http://localhost:5000/api/create_playlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-Running-Mate-Token': runningMateToken,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Failed to create playlist');
            }

            const responseData = await response.json();
            navigate('/display-playlist', { state: { playlist: responseData } });

        } catch (error) {
            console.error('Error creating playlist:', error);
            setLoading(false);
            setIsButtonDisabled(false); // Re-enable button if there's an error
        }
    };

    if (loading) {
        return <LoadingPage />; // Show loading page while playlist is being created
    }

    return (
        <div className="create-playlist-container">
            <h2>This is where the magic happens...</h2>
            <button onClick={handleCreatePlaylist} disabled={isButtonDisabled}>
                {isButtonDisabled ? 'Processing...' : 'Create Playlist'}
            </button>
        </div>
    );
};

export default CreatePlaylistAction;
