import React from 'react';
import './LoadingPage.css';

const LoadingPage = () => {
    return (
        <div className="loading-page-container">
            <h2>Your playlist is being created!</h2>
            <p>Remember:</p>
            <ul>
                <li>Always be aware of your surroundings when running with music.</li>
                <li>Your playlist is there to support your activity, so run to what feels comfortable.</li>
                <li>We can't guarantee performance but we hope this will help motivate you.</li>
            </ul>
            <div className="loading-indicator"></div>
            <p>Please wait...</p>
        </div>
    );
};

export default LoadingPage;
