import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LoggedInHomepage.css';

function LoggedInHomepage() {
  const navigate = useNavigate();

  const navigateToChooseRunType = () => {
    navigate('/ChooseRunType'); 
  };

  const navigateToSavedPlaylists = () => {
    navigate('/saved-playlists'); 
  };

  return (
    <div className="LoggedInHomepage">
      <h1>Welcome Back!</h1>
      <div className="button-container">
        <button>My Training Plan</button>
        <button onClick={navigateToChooseRunType}>Create Playlist for a Single Run</button>
        <button onClick={navigateToSavedPlaylists}>Saved Playlists</button>
        <button>Training Plan Library</button>
      </div>
    </div>
  );
}

export default LoggedInHomepage;
