// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-playlist-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #333;
    text-align: center;
}

.display-playlist-container h2 {
    color: white;
}

.playlists {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playlist {
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #444;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.playlist-title {
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.song-item {
    padding: 10px;
    color: black;
    background-color: white;
    margin: 5px 0;
    border-radius: 5px;
    word-wrap: break-word;
    text-align: center;
}

.empty-item {
    visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Playlist/SavedPlaylists/SavedPlaylists.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".display-playlist-container {\n    max-width: 100%;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    background-color: #333;\n    text-align: center;\n}\n\n.display-playlist-container h2 {\n    color: white;\n}\n\n.playlists {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.playlist {\n    width: 100%;\n    max-width: 600px;\n    margin: 10px 0;\n    border: 1px solid #555;\n    border-radius: 5px;\n    background-color: #444;\n    padding: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.playlist-title {\n    font-weight: bold;\n    color: white;\n    margin-bottom: 10px;\n}\n\nul {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n    width: 100%;\n}\n\n.song-item {\n    padding: 10px;\n    color: black;\n    background-color: white;\n    margin: 5px 0;\n    border-radius: 5px;\n    word-wrap: break-word;\n    text-align: center;\n}\n\n.empty-item {\n    visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
