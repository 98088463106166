import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SavedPlaylists.css';

const SavedPlaylists = () => {
  const [playlists, setPlaylists] = useState({});
  const [error, setError] = useState(null);
  const [expandedPlaylist, setExpandedPlaylist] = useState(null); // expanded playlist
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await axios.get('/api/user-info', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const savedPlaylists = response.data.SavedPlaylists;
        setPlaylists(savedPlaylists);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setError(error.response ? error.response.data.message : 'Error fetching playlists');
      }
    };

    fetchPlaylists();
  }, []);

  const handleHomeClick = () => {
    navigate('/LoggedInHomepage');
  };

  const handlePlaylistClick = (title) => {
    if (expandedPlaylist === title) {
      setExpandedPlaylist(null);
    } else {
      setExpandedPlaylist(title);
    }
  };

  const getMaxRowLength = () => {
    let maxLength = 0;
    Object.values(playlists).forEach(playlist => {
      if (Array.isArray(playlist) && playlist.length > maxLength) {
        maxLength = playlist.length;
      }
    });
    return maxLength;
  };

  const maxRowLength = getMaxRowLength();

  return (
    <div className="display-playlist-container">
      <h2>Saved Playlists</h2>
      {error && <p>{error}</p>}
      {playlists && Object.keys(playlists).length > 0 ? (
        <div className="playlists">
          {Object.entries(playlists).map(([title, songs], index) => (
            <div key={index} className="playlist">
              <div
                className="playlist-title"
                onClick={() => handlePlaylistClick(title)}
              >
                {title}
              </div>
              {expandedPlaylist === title && (
                <ul>
                  {Array.isArray(songs) ? songs.map((song, idx) => (
                    <li key={idx} className="song-item">
                      <strong>Name:</strong> {song.name}<br/>
                      <strong>Artist:</strong> {song.artist}<br/>
                      <strong>BPM:</strong> {song.bpm}<br/>
                      <strong>Duration:</strong> {song.duration_ms} ms<br/>
                      <strong>Stage:</strong> {song.stage}
                    </li>
                  )) : (
                    <li className="song-item error-item">Error: Playlist data is not an array</li>
                  )}
                  {Array.from({ length: maxRowLength - (Array.isArray(songs) ? songs.length : 0) }, (_, idx) => (
                    <li key={`empty-${idx}`} className="song-item empty-item"></li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No saved playlists found.</p>
      )}
      <button className="home-button" onClick={handleHomeClick}>Home</button>
    </div>
  );
};

export default SavedPlaylists;
