// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta-buttons {
    display: flex;
    gap: 20px;
}

  button {
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      background-color: #E3D915; 
      color: black;
      border: none;
      border-radius: 5px;
  }
  
  button:hover {
      background-color: #B6AE12;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Buttons/Buttons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;EAEE;MACI,kBAAkB;MAClB,eAAe;MACf,eAAe;MACf,yBAAyB;MACzB,YAAY;MACZ,YAAY;MACZ,kBAAkB;EACtB;;EAEA;MACI,yBAAyB;EAC7B","sourcesContent":[".cta-buttons {\n    display: flex;\n    gap: 20px;\n}\n\n  button {\n      padding: 10px 20px;\n      font-size: 16px;\n      cursor: pointer;\n      background-color: #E3D915; \n      color: black;\n      border: none;\n      border-radius: 5px;\n  }\n  \n  button:hover {\n      background-color: #B6AE12;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
