export const authenticateSpotify = async () => {
    const accessToken = localStorage.getItem('spotify_access_token');
    const runningMateToken = localStorage.getItem('token');

    if (!runningMateToken) {
        console.error('User is not logged in to Running Mate.');
        window.location.href = '/login';  // Redirect to Running Mate login if token is missing
        return null;
    }

    if (!accessToken) {
        console.error('No Spotify access token found. Redirecting to Spotify login.');

        // Redirect to Spotify for authentication
        window.location.href = 'https://www.therunningmate.co.uk/api/spotify-login?redirect_uri=/store-token';
        // window.location.href = 'http://localhost:5000/api/spotify-login?redirect_uri=/store-token';
        return null;
    }

    return accessToken;
};
