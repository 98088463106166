import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ChooseMusicSource.css';

const ChooseMusicSource = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const activity = location.state.activity;

    const handleUseSaved = () => {
        navigate('/CreatePlaylistAction', { state: { activity } });
    };

    const handleSelectPreferences = () => {
        navigate('/specific-music', { state: { activity } });
    };

    return (
        <div className="choose-music-container">
            <h2>Set music</h2>
            <div className="buttons-container">
                <button onClick={handleUseSaved}>Use saved music preferences</button>
                <button onClick={handleSelectPreferences}>Select music preferences for this run</button>
            </div>
        </div>
    );
};

export default ChooseMusicSource;
