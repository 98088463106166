import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const StoreToken = () => {
    const navigate = useNavigate();
    const tokenProcessed = useRef(false);

    useEffect(() => {
        const processToken = () => {
            if (tokenProcessed.current) return;  // Exit if the token has already been processed

            const urlParams = new URLSearchParams(window.location.search);
            const spotifyAccessToken = urlParams.get('access_token');
            const redirectUri = urlParams.get('redirect_uri') || '/ChooseRunType'; // Default to choose run type

            if (spotifyAccessToken) {
                console.log('URL Parameters:', window.location.search);
                console.log('Access Token:', spotifyAccessToken);
                console.log('Redirect URI:', redirectUri);

                // Store the Spotify access token
                localStorage.setItem('spotify_access_token', spotifyAccessToken);
                console.log('Stored Spotify Access Token:', spotifyAccessToken);

                // Mark the token as processed
                tokenProcessed.current = true;

                // Ensure the Running Mate JWT is still in localStorage
                const runningMateToken = localStorage.getItem('token');
                if (!runningMateToken) {
                    console.error('Running Mate JWT is missing. Redirecting to login.');
                    navigate('/login');
                    return;
                }

                // Redirect to the intended page
                navigate(redirectUri);
            } else {
                console.error('No Spotify access token found in the URL.');
                navigate('/login'); // Redirect to login if no Spotify token is found
            }
        };

        // Process the token immediately on component mount
        processToken();
    }, [navigate]);

    return <div>Processing login...</div>;
};

export default StoreToken;
