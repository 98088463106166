import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './DisplayPlaylist.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const DisplayPlaylist = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [playlist, setPlaylist] = useState([]);
    const [title, setTitle] = useState('My Running Mate Playlist');
    const [playlistState, setPlaylistState] = useState([]);
    const [replacingSong, setReplacingSong] = useState(null);
    const titleInputRef = useRef(null);

    useEffect(() => {
        if (location.state && location.state.playlist) {
            const { playlist } = location.state.playlist;
            setPlaylist(playlist);
            setPlaylistState(playlist);
        }
    }, [location.state]);

    useEffect(() => {
        console.log('Received playlist:', playlist);
    }, [playlist]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleSavePlaylist = async () => {
        const username = localStorage.getItem('username');
        const accessToken = localStorage.getItem('spotify_access_token');

        const data = {
            username: username,
            title: title || 'My Running Mate Playlist',
            playlist: playlistState,
        };

        try {
            const response = await fetch('https://www.therunningmate.co.uk/api/save_playlist', {
            // const response = await fetch('http://localhost:5000/api/save_playlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                alert('Playlist saved!');
            } else {
                const errorData = await response.json();
                alert(`Failed to save playlist: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while saving the playlist.');
        }
    };

    const handleUploadToSpotify = async () => {
    //     const username = localStorage.getItem('username');
    //     const accessToken = localStorage.getItem('spotify_access_token');

    //     const data = {
    //         username: username,
    //         title: title || 'My Running Mate Playlist',
    //         playlist: playlistState,
    //     };

    //     try {
    //         //const response = await fetch('https://www.therunningmate.co.uk/api/upload_playlist', { 
    //         const response = await fetch('http://localhost:5000/api/upload_playlist', {
    //         method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${accessToken}`,  // Pass the access token here
    //             },
    //             body: JSON.stringify(data)
    //         });

    //         if (response.ok) {
    //             alert('Playlist uploaded to Spotify!');
    //         } else {
    //             const errorData = await response.json();
    //             alert(`Failed to upload playlist: ${errorData.error}`);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         alert('An error occurred while uploading the playlist.');
    //     }

    alert('Uploading to Spotify is temporarily unavailable.');        
    };

    const handleMoveItem = (stageKey, index, direction) => {
        const updatedPlaylist = { ...groupedPlaylist };
        const items = updatedPlaylist[stageKey];
        const [movedItem] = items.splice(index, 1);
        items.splice(index + direction, 0, movedItem);
        const updatedPlaylistState = Object.values(updatedPlaylist).flat();
        setPlaylistState(mergeBellsBackIntoPlaylist(updatedPlaylistState));
    };

    const handleReplaceItem = async (stageKey, index) => {
        if (window.confirm('Are you sure you want to replace this song?')) {
            const songToReplace = groupedPlaylist[stageKey][index];
            setReplacingSong({ stageKey, index });
            const accessToken = localStorage.getItem('spotify_access_token');
    
            const data = {
                song: songToReplace,
                access_token: accessToken,
            };
    
            try {
                const response = await fetch('https://www.therunningmate.co.uk/api/replace_song', { //
                // const response = await fetch('http://localhost:5000/api/replace_song', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data)
                });
    
                if (response.ok) {
                    const newSong = await response.json();
                    const updatedPlaylist = { ...groupedPlaylist };
                    updatedPlaylist[stageKey][index] = newSong;
    
                    // Convert grouped playlist back to an array while preserving order
                    const updatedPlaylistState = Object.keys(updatedPlaylist).flatMap(key => updatedPlaylist[key]);
    
                    // Merge the bells back into the updated playlist
                    setPlaylistState(mergeBellsBackIntoPlaylist(updatedPlaylistState));
                } else {
                    const errorData = await response.json();
                    alert(`Failed to replace song: ${errorData.error}`);
                }
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred while replacing the song.');
            } finally {
                setReplacingSong(null);
            }
        }
    };
    

    const handleActionChange = (stageKey, index, action) => {
        if (action === 'move-up') handleMoveItem(stageKey, index, -1);
        else if (action === 'move-down') handleMoveItem(stageKey, index, 1);
        else if (action === 'replace') handleReplaceItem(stageKey, index);
        document.getElementById(`action-${stageKey}-${index}`).value = ''; // reset action to default
    };

    const groupByStage = (playlist) => {
        if (!Array.isArray(playlist)) return {};

        let currentStage = null;
        let stageCount = -1;
        return playlist.reduce((groups, item) => {
            if (item.stage !== "Bell") {
                if (item.stage !== currentStage) {
                    currentStage = item.stage;
                    stageCount += 1;
                }

                const stageKey = `${item.stage}-${stageCount}`;
                if (!groups[stageKey]) {
                    groups[stageKey] = [];
                }
                groups[stageKey].push(item);
            }
            return groups;
        }, {});
    };

    const mergeBellsBackIntoPlaylist = (updatedPlaylist) => {
        const bells = playlistState.filter(item => item.stage === "Bell");
    
        // Place bells back into their original positions
        const finalPlaylistState = [...updatedPlaylist];
    
        bells.forEach(bell => {
            const bellIndex = playlist.indexOf(bell);
            finalPlaylistState.splice(bellIndex, 0, bell);
        });
    
        return finalPlaylistState;
    };
    

    const groupedPlaylist = playlistState ? groupByStage(playlistState) : {};

    const handleShare = (platform) => {
        const url = encodeURIComponent(window.location.href);
        const text = encodeURIComponent(`Check out my playlist: ${title}`);

        let shareUrl = '';
        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}`;
                break;
            case 'whatsapp':
                shareUrl = `https://wa.me/?text=${text} ${url}`;
                break;
            default:
                return;
        }

        window.open(shareUrl, '_blank');
    };

    return (
        <div className="display-playlist-container">
            <div className="title-input-container">
                <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    className="playlist-title-input"
                    ref={titleInputRef}
                    disabled={replacingSong !== null} // disable input if replacing a song
                />
                <i className="fas fa-edit edit-icon" onClick={() => titleInputRef.current.focus()}></i> {/* Edit icon */}
            </div>
            {replacingSong && <p>Replacing song, please wait...</p>} {/* Show a loading message */}

            {playlistState ? (
                Object.keys(groupedPlaylist).map((stageKey, index) => {
                    const stageName = stageKey.split('-')[0];
                    return (
                        <div key={index} className="stage-group">
                            <h3 className="stage-title">{stageName}</h3>
                            <div className="playlist-header">
                                <div className="playlist-header-item">Song</div>
                                <div className="playlist-header-item">Artist</div>
                                <div className="playlist-header-item">Actions</div>
                            </div>
                            {groupedPlaylist[stageKey].map((item, idx) => (
                                <div className="playlist-item" key={idx}>
                                    <div className="playlist-details">
                                        <p>{item.name}</p>
                                        <p>{item.artist}</p>
                                    </div>
                                    <div className="actions">
                                        <select
                                            id={`action-${stageKey}-${idx}`}
                                            onChange={(e) => handleActionChange(stageKey, idx, e.target.value)}
                                            defaultValue=""
                                            disabled={replacingSong !== null} // disable actions if replacing a song
                                        >
                                            <option value="" disabled>Select Action</option>
                                            <option value="move-up" disabled={idx === 0}>Move Up</option>
                                            <option value="move-down" disabled={idx === groupedPlaylist[stageKey].length - 1}>Move Down</option>
                                            <option value="replace">Replace</option>
                                        </select>
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                })
            ) : (
                <p className="playlist-item">Loading...</p>
            )}

            <div className="button-container">
                <button className="save-button" onClick={handleSavePlaylist} disabled={replacingSong !== null}>Save Playlist</button>
                <button className="upload-button" onClick={handleUploadToSpotify} disabled={replacingSong !== null}>Upload Playlist to Spotify</button>
                <button className="home-button" onClick={() => navigate('/LoggedInHomepage')} disabled={replacingSong !== null}>Finish</button>
            </div>

            <div className="share-container">
                <p className="share-label">Share with:</p>
                <div className="share-buttons">
                    <i className="fab fa-facebook share-icon" onClick={() => handleShare('facebook')} title="Share on Facebook"></i>
                    <i className="fab fa-twitter share-icon" onClick={() => handleShare('twitter')} title="Share on Twitter"></i>
                    <i className="fab fa-linkedin share-icon" onClick={() => handleShare('linkedin')} title="Share on LinkedIn"></i>
                    <i className="fab fa-whatsapp share-icon" onClick={() => handleShare('whatsapp')} title="Share on WhatsApp"></i>
                </div>
            </div>
        </div>
    );
};

export default DisplayPlaylist;
