// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manual-entry-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #282c34;
  }
  
  .manual-entry-container h2 {
    text-align: center;
    color: white;
  }
  
  .manual-entry-container p {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .zones-form {
    display: flex;
    justify-content: space-between;
  }
  
  .left-column,
  .right-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .left-column label,
  .right-column label {
    color: white;
  }
  
  .left-column input,
  .right-column input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .right-column .spacer {
    height: 58px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Account/ManualEntry/ManualEntry.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;;IAEE,YAAY;EACd;;EAEA;;IAEE,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd","sourcesContent":[".manual-entry-container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    background-color: #282c34;\n  }\n  \n  .manual-entry-container h2 {\n    text-align: center;\n    color: white;\n  }\n  \n  .manual-entry-container p {\n    text-align: center;\n    margin-bottom: 20px;\n    color: white;\n  }\n  \n  .zones-form {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .left-column,\n  .right-column {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .left-column label,\n  .right-column label {\n    color: white;\n  }\n  \n  .left-column input,\n  .right-column input {\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .right-column .spacer {\n    height: 58px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
