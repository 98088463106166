import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SetHRZ.css';

const SetHRZ = () => {
  const navigate = useNavigate();

  const handleManualEntry = () => {
    navigate('/manualEntry');
  };

  const handleCalculate = () => {
    navigate('/calculateHR');
  };

  return (
    <div className="set-hrz-container">
      <h2>Set Heart Rate Zone</h2>
      <p>
        Research says that there may be benefit to keeping your heart beat within specific zones for different types of runs.
        By calculating your personal heart rate zones, we can match the songs in your playlist to help guide your effort at each stage.
        Choose how you would like us to set up your heart rate zones.
      </p>
      <div className="buttons-container">
        <button onClick={handleManualEntry}>Enter your heart rate manually</button>
        <button onClick={handleCalculate}>Let us calculate your heart rate</button>
      </div>
    </div>
  );
};

export default SetHRZ;

