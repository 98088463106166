// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-music-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #333;
}

.select-music-container h2 {
  text-align: center;
  color: white;
}

.select-music-container p {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.genre-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px 20px;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreatePlaylist/ChooseSpecificMusic/ChooseSpecificMusic.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;EACzC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,YAAY;AACd","sourcesContent":[".select-music-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  background-color: #333;\n}\n\n.select-music-container h2 {\n  text-align: center;\n  color: white;\n}\n\n.select-music-container p {\n  text-align: center;\n  margin-bottom: 20px;\n  color: white;\n}\n\n.genre-list {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 15px 20px;\n}\n\n.form-group {\n  display: flex;\n  align-items: center;\n}\n\n.form-group label {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
