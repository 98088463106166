import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ChooseSpecificMusic.css';

// top 10 genres from https://audiocaptain.com/most-popular-music-genres/
const allGenres = [
  'classical', 'country', 'electronic', 'hip-hop', 'k-pop', 'latin', 'metal', 'pop', 'r-n-b', 'rock',
  'acoustic', 'afrobeat', 'alt-rock', 'alternative', 'ambient', 'anime', 'black-metal',
  'bluegrass', 'blues', 'bossanova', 'brazil', 'breakbeat', 'british', 'cantopop',
  'chicago-house', 'children', 'chill', 'club', 'comedy',
  'dance', 'dancehall', 'death-metal', 'deep-house', 'detroit-techno', 'disco',
  'disney', 'drum-and-bass', 'dub', 'dubstep', 'edm', 'electro', 'emo',
  'folk', 'forro', 'french', 'funk', 'garage', 'german', 'gospel', 'goth', 'grindcore',
  'groove', 'grunge', 'guitar', 'happy', 'hard-rock', 'hardcore', 'hardstyle',
  'heavy-metal', 'holidays', 'honky-tonk', 'house', 'idm', 'indian',
  'indie', 'indie-pop', 'industrial', 'iranian', 'j-dance', 'j-idol', 'j-pop',
  'j-rock', 'jazz', 'kids', 'latino', 'malay', 'mandopop',
  'metal-misc', 'metalcore', 'minimal-techno', 'movies', 'mpb', 'new-age',
  'new-release', 'opera', 'pagode', 'party', 'philippines-opm', 'piano',
  'pop-film', 'post-dubstep', 'power-pop', 'progressive-house', 'psych-rock', 'punk',
  'punk-rock', 'rainy-day', 'reggae', 'reggaeton', 'road-trip',
  'rock-n-roll', 'rockabilly', 'romance', 'sad', 'salsa', 'samba', 'sertanejo',
  'show-tunes', 'singer-songwriter', 'ska', 'sleep', 'songwriter', 'soul',
  'soundtracks', 'spanish', 'study', 'summer', 'swedish', 'synth-pop', 'tango',
  'techno', 'trance', 'trip-hop', 'turkish', 'work-out', 'world-music'
];

const initialGenres = allGenres.slice(0, 10);
const remainingGenres = allGenres.slice(10);

const SelectMusic = () => {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { activity } = location.state || {};

  const handleGenreChange = (genre) => {
    setSelectedGenres((prevSelectedGenres) =>
      prevSelectedGenres.includes(genre)
        ? prevSelectedGenres.filter((g) => g !== genre)
        : [...prevSelectedGenres, genre]
    );
  };

  const handleSubmit = () => {
    navigate('/CreatePlaylistAction', { state: { activity, selectedGenres } });
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="select-music-container">
      <h2>Set your music preferences</h2>
      <p>
        These are the genres that will be included in your playlists. These can
        be updated at any time, and you will have the choice to select specific
        preferences for individual runs.
      </p>
      <form className="genre-list">
        {initialGenres.map((genre) => (
          <div key={genre} className="form-group">
            <label>
              <input
                type="checkbox"
                value={genre}
                checked={selectedGenres.includes(genre)}
                onChange={() => handleGenreChange(genre)}
              />
              {genre}
            </label>
          </div>
        ))}
        {showMore &&
          remainingGenres.map((genre) => (
            <div key={genre} className="form-group">
              <label>
                <input
                  type="checkbox"
                  value={genre}
                  checked={selectedGenres.includes(genre)}
                  onChange={() => handleGenreChange(genre)}
                />
                {genre}
              </label>
            </div>
          ))}
      </form>
      <button type="button" onClick={toggleShowMore}>
        {showMore ? 'Show Less' : 'Show More'}
      </button>
      <button type="button" onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default SelectMusic;
