import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ChooseRunType.css';

function ChooseRunType() {
  const navigate = useNavigate();

  const handleRunTypeClick = (runType, path) => {
    navigate(path, { state: { runType } });
  };

  return (
    <div className="ChooseRunType">
      <h1>Choose the type of run</h1>
      <div className="button-container">
        <button onClick={() => handleRunTypeClick('base_run', '/intervals-setup')}>Base run</button>
        <button onClick={() => handleRunTypeClick('long_run', '/intervals-setup')}>Long run</button>
        <button onClick={() => handleRunTypeClick('progression_run', '/intervals-setup')}>Progression run</button>
        <button onClick={() => handleRunTypeClick('interval_training', '/intervals-setup')}>Intervals</button>
      </div>
    </div>
  );
}

export default ChooseRunType;
