// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #0a03a3;
  color: white;
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.Login-header h2 {
  color: white;
}

.Login-logo img {
  width: 100px; 
  height: auto; 
}

.Login-main {
  padding: 20px;
  font-size: 16px; 
  color: white;
}

.form-group {
  margin-bottom: 20px; 
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: white;
  text-align: left; 
  margin-bottom: 5px; 
}

.form-group input {
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  font-size: inherit;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Account/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;EACzC,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".Login {\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  background-color: #0a03a3;\n  color: white;\n  text-align: center;\n  font-size: calc(10px + 2vmin);\n}\n\n.Login-header h2 {\n  color: white;\n}\n\n.Login-logo img {\n  width: 100px; \n  height: auto; \n}\n\n.Login-main {\n  padding: 20px;\n  font-size: 16px; \n  color: white;\n}\n\n.form-group {\n  margin-bottom: 20px; \n  display: flex;\n  flex-direction: column;\n}\n\n.form-group label {\n  color: white;\n  text-align: left; \n  margin-bottom: 5px; \n}\n\n.form-group input {\n  padding: 8px;\n  border-radius: 3px;\n  border: 1px solid #ccc;\n  background-color: #f0f0f0;\n  font-size: inherit;\n}\n\n.error {\n  color: red;\n  margin-top: 10px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
