import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from '../../../assets/logo192.png';
import { authenticateSpotify } from '../../../components/SpotifyAuth/SpotifyAuth'; // Import the Spotify authentication

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    axios.defaults.baseURL = 'https://www.therunningmate.co.uk'; 
    // axios.defaults.baseURL = 'http://localhost:5000';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('/api/login', {
                Username: username,
                Password: password
            });

            const token = response.data.token;
            if (token) {
                // Store the JWT in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                console.log('Stored JWT:', token);

                const accessToken = await authenticateSpotify(); 

                if (accessToken) {
                    // If Spotify authentication is successful, navigate to the homepage
                    navigate('/LoggedInHomepage');
                } else {
                    throw new Error('Spotify authentication failed');
                }
            } else {
                throw new Error('Token not received');
            }

            setError(null);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                console.error('Server responded with:', error.response.status);
                console.error('Data:', error.response.data);
                setError(error.response.data.message || 'Login failed');
            } else if (error.request) {
                console.error('No response received:', error.request);
                setError('No response from server');
            } 
            //else {
                //console.error('Error:', error.message);
                //setError('Something went wrong');
            //}
        }
    };

    return (
        <div className="Login">
            <div className="Login-header">
                <div className="Login-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <h2>Login:</h2>
            </div>
            <div className="Login-main">
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>{loading ? 'Logging in...' : 'Login'}</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
