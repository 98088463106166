// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.set-hrz-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #333;
  }
  
  .set-hrz-container h2 {
    text-align: center;
    color: white;
  }
  
  .set-hrz-container p {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  `, "",{"version":3,"sources":["webpack://./src/pages/Account/SetHRZ/SetHRZ.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX","sourcesContent":[".set-hrz-container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    background-color: #333;\n  }\n  \n  .set-hrz-container h2 {\n    text-align: center;\n    color: white;\n  }\n  \n  .set-hrz-container p {\n    text-align: center;\n    margin-bottom: 20px;\n    color: white;\n  }\n  \n  .buttons-container {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
